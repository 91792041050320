.App {
  font-family: sans-serif;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 95vh;
  width: 95vw;
}

.textBox {
  height: 25vh;
}

.ruby {
  width: 30vw;
}

.huck {
  width: 15vw;
}

.shelly {
  width: 15vw;
  height: 100%;
}

.slide-up {
  position: absolute;
  top: 115vh;
  left: 30vw;
  -webkit-animation: slide-up 6s forwards;
  -webkit-animation-delay: 8s;
  animation: slide-up 6s forwards;
  animation-delay: 8s;
}

.slide-right {
  position: absolute;
  right: 2000px;
  -webkit-animation: slide-right 6s forwards;
  -webkit-animation-delay: 2s;
  animation: slide-right 6s forwards;
  animation-delay: 2s;
}

.slide-left {
  position: absolute;
  left: 2000px;
  -webkit-animation: slide-left 0.5s forwards;
  animation: slide-left 6s forwards;
}
@-webkit-keyframes slide-up {
  15% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(-90deg);
  }
  100% {
    top: 25vh;
    transform: rotate(0deg);
  }
}

@keyframes slide-up {
  15% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(-90deg);
  }
  100% {
    top: 30vh;
    transform: rotate(0deg);
  }
}

@-webkit-keyframes slide-right {
  100% {
    right: 60vw;
  }
}

@keyframes slide-right {
  100% {
    right: 60vw;
  }
}

@-webkit-keyframes slide-left {
  100% {
    left: 60vw;
  }
}

@keyframes slide-left {
  100% {
    left: 60vw;
  }
}

@media only screen and (max-width: 800px) {
  .ruby {
    width: 60vw;
  }
  .shelly {
    width: 40vw;
  }
  .huck {
    width: 40vw;
  }

  .slide-up {
    left: 15vw;
  }

  @-webkit-keyframes slide-up {
    15% {
      transform: rotate(90deg);
    }
    30% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(90deg);
    }
    75% {
      transform: rotate(-90deg);
    }
    100% {
      top: 40vh;
      transform: rotate(0deg);
    }
  }

  @keyframes slide-up {
    15% {
      transform: rotate(90deg);
    }
    30% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(90deg);
    }
    75% {
      transform: rotate(-90deg);
    }
    100% {
      top: 40vh;
      transform: rotate(0deg);
    }
  }
}
